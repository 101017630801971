.container {
  // position: fixed;
  padding: 30px;
  min-height: 220px;
  align-self: top;
  background-color: white;
  border-radius: 10px;
  text-align: center;
  min-width: 200px;
  width: 20%;
  max-width: 250px;

  @media only screen and (max-width: 900px) {
    min-width: 200px;
    width: 100%;
    max-width: unset;
    margin-bottom: 0;
  }

  & > h3 {
    font-size: 1.125rem;
    font-weight: 500;
    margin-bottom: 20px;
  }

  & > p {
    font-weight: 500;
    font-size: 4.375rem;
    margin: 0;
  }

  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    font-size: 1.75rem;
    font-weight: 500;
    margin-bottom: 40px;
  }

  & > button {
    width: 100%;
    background-color: #f0f7ff;
    color: #298cfd;
    outline: none;
    border: 1px solid #298cfd;
    padding: 12px 24px;
    border-radius: 40px;

    &:hover {
      background-color: #e8eaed;
      color: #298cfd;
      opacity: 0.8;
      cursor: pointer;
    }
  }

  .buttonWithdraw {
    width: 100%;
    background-color: #f0f7ff;
    color: #298cfd;
    outline: none;
    border: 1px solid #298cfd;
    padding: 12px 24px;
    border-radius: 40px;

    &:hover {
      background-color: #e8eaed;
      color: #298cfd;
      opacity: 0.8;
      cursor: pointer;
    }
  }
}
