body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Roboto';
  color: #484848;
  box-sizing: border-box;
}

#root {
  overflow: hidden;
  --outline: rgba(#fff, 0.4);
}
